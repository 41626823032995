/**=====================
  12. Calendar CSS Start
==========================**/
// calender
.rbc-calendar {
	height: 100vh !important;
}

#external-events .fc-event {
	margin: 1em 0;
	cursor: move;
}
/**=====================
  12. Calendar CSS End
==========================**/

.rbc-time-slot {
	display: flex;
	align-items: center; /* Centro verticalmente */
	justify-content: center; /* Centro orizzontalmente */
	height: 100%; /* Assicura che la cella occupi tutto lo spazio disponibile */
	padding: 0; /* Rimuove eventuali padding */
	font-size: 14px; /* Dimensione del testo (opzionale) */
}

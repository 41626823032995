/* Stile per la versione desktop */
.header-desktop {
	height: 12vh;
	background-color: #003f49;
	width: 100vw;
}

.header-desktop-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	padding-left: 80px;
	padding-right: 80px;
}
.access-button {
	background-color: #003f49 !important;
	color: white;
	border: 2px solid #d5a940 !important;

	border: none;
	font-size: 16px;
	font-weight: bold;
}
.header-logo {
	height: auto;
	width: 10%;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
	background-color: rgba(255, 255, 255, 0);
	border: 1px solid #d9d9d9;
}

.header-options {
	display: flex;
	align-items: center;
	width: 60%;
	gap: 20px;
	justify-content: flex-end;
}

/* Stile per la versione mobile */
.header-mobile {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 12vh;
	background-color: #003f49;
	padding: 0 20px;
	width: 100vw;
}

.custom-select-mobile .ant-select-selection-item {
	color: black;
	font-weight: 600;
}

.header-logo-mobile {
	width: 20%;
}

.menu-button {
	background: none;
	border: none;
	color: white;
	font-size: 24px;
}

/* Drawer styling */
.ant-drawer-title {
	font-size: 18px;
	font-weight: bold;
}

.ant-select-dropdown
	.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: #003f49;
	color: white;
}

.ant-select-dropdown .ant-select-item-option {
	color: #fff; /* Scegli il colore che preferisci */
}

.ant-select-single {
	color: #fff;
}
.ant-select-selection-item {
	color: #000; /* Scegli il colore bianco */
}

/* Cambia il colore dell'opzione selezionata */
.select .ant-select-selection-item {
	color: #000; /* Scegli il colore bianco */
}

/* Opzionale: Cambia anche il background della selezione, se desiderato */
.ant-select-selector {
	background-color: #ffff; /* Scegli il colore di sfondo che preferisci */
}

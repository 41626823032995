/* .rbc-time-header-content {
	font-size: 14px;
	font-weight: 600;
	background-color: #f7f8f8;
}
.rbc-time-slot .rbc-time-slot .rbc-label {
	border-bottom: 1px solid #f7f8f8;
	font-weight: 600;
	color: #000;
}

.rbc-time-content {
	border: none;
} */
rbc-timeslot-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: content;
}

.rbc-time-slot {
	flex: 0 0 !important;
}

.rbc-time-column .rbc-timeslot-group {
	flex: 0 0 !important;
}

.rbc-time-column {
	height: fit-content !important;
	display: inline !important;
	min-height: auto !important;
}

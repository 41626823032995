/**=====================
  77. Customizer CSS Start
==========================**/
.customizer-links {
	position: fixed;
	right: 00px;
	top: 40%;
	background-color: $white;
	z-index: 3;
	box-shadow: 0 0 15px 3px rgba(176, 185, 189, 0.3);
	transition: all 0.3s ease;
	&.open {
		right: 330px;
		transition: all 0.3s ease;
	}
	.nav-item {
		+ .nav-item {
			border-top: 1px solid rgba($primary-color, 0.1);
		}
	}
	.nav-link {
		padding: 0;
		border-radius: 0;
		position: relative;
		.settings {
			i {
				color: var(--theme-default);
				font-size: 24px;
				padding: 15px 17px;
				display: inline-block;
				cursor: pointer;
				margin: 0;
			}
		}
		&.active {
			.settings {
				i {
					color: $white;
					background-color: var(--theme-default);
				}
			}
		}
		& + .nav-link {
			border-top: 1px solid rgba($primary-color, 0.1);
		}
		span {
			position: absolute;
			right: -80px;
			width: max-content;
			text-align: right;
			padding: 7px 10px;
			display: block;
			top: 8px;
			border-radius: 5px;
			transform: scale(0);
			transition: all 0.3s ease;
			opacity: 0;
			color: $white;
			background-color: var(--theme-default);
		}
		&:after {
			content: "";
			position: absolute;
			top: 17px;
			left: -10px;
			width: 0;
			height: 0;
		}
		&:hover {
			span {
				opacity: 1;
				right: 68px;
				transform: scale(1);
				transition: all 0.3s ease;
			}
			&:after {
				opacity: 1;
				transform: scale(1);
				border-top: 8px solid transparent;
				border-left: 10px solid var(--theme-default);
				border-bottom: 8px solid transparent;
				transition: all 0.3s ease;
			}
		}
	}
}
.customizer-contain {
	direction: ltr;
	position: fixed;
	height: calc(100vh);
	top: 80px;
	width: 333px;
	right: -335px;
	background-color: $white;
	z-index: 99;
	transition: all 0.3s ease;
	&.open {
		right: 0px;
		transition: all 0.3s ease;
		box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
	}
	li {
		position: relative;
		&.active {
			&:before {
				content: "";
				position: absolute;
				height: 100%;
				width: 100%;
				z-index: 2;
				left: 0;
				top: 0;
				font-size: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $dark-color;
				box-shadow: 0 0 11px 5px rgb(226, 226, 226);
			}
		}
	}
	h6 {
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.5px;
		color: #212529;
	}
	.customizer-header {
		padding: 15px 25px;
		border-bottom: 1px solid $light-gray;
		.icon-close {
			position: absolute;
			font-size: 18px;
			cursor: pointer;
			font-weight: 900;
			right: 30px;
			color: var(--theme-default);
		}
		h5 {
			font-weight: 700;
			text-transform: uppercase;
			color: #313131;
		}
		p {
			color: #313131;
		}
	}
	.customizer-body {
		padding: 15px 25px;
		max-height: calc(100vh - 170px);
		overflow-y: scroll;
		.layout-grid {
			.bg-light {
				background-color: $light-gray !important;
			}
			&.customizer-mix {
				.color-layout {
					height: 70px;
					width: 80px;
					border: 1px solid #f6f7fb;
					border-radius: 5px;
					display: inline-block;
					margin-bottom: 7px;
					margin-right: 3px;
				}
			}
		}
		.tab-pane {
			> h6 {
				position: relative;
				margin-top: 25px;
				padding-top: 25px;
				margin-bottom: 20px;
				border-top: 1px solid #f3f3f3;
				font-size: 15px;
				&:before {
					content: "";
					position: absolute;
					height: 3px;
					width: 14px;
					border-bottom: 2px dotted $dark-color;
					bottom: -4px;
				}
				&:first-child {
					border-top: 0;
					padding-top: 0;
					margin-top: 0;
				}
			}
			.unlimited-color-layout {
				display: flex;
				align-items: center;
				input {
					height: 40px;
					width: 40px;
					border-radius: 5px;
					display: inline-block;
					margin-right: 3px;
					border: 1px solid #b8b8b8;
					padding: 3px;
					background-color: $light-background;
				}
			}
		}
		.nac-pills {
			border: 1px solid #f1f1f1;
			margin-bottom: 10px;
			border-radius: 5px;
			padding: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			.nav-item {
				width: 33.33%;
				text-align: center;
				.nav-link {
					border-radius: 5px;
					&.active {
						background-color: var(--theme-default);
						color: $white;
					}
				}
			}
			.nav-link {
				padding: 0.2rem 1rem;
			}
		}
		li {
			cursor: pointer;
			opacity: 0.9;
			&.active {
				opacity: 1;
			}
		}
		.fade {
			&:not(.show) {
				display: none;
			}
		}
		.color-layout {
			height: 40px;
			width: 40px;
			border-radius: 5px;
			display: inline-block;
			margin-bottom: 7px;
			margin-right: 3px;
			border: 1px solid #b8b8b8;
			padding: 3px;
			> div {
				background-color: #477673;
				height: 100%;
				width: 100%;
				border-radius: 3px;
			}
			.body {
				.contain {
					position: absolute;
					height: auto;
					width: 100%;
					li {
						height: 1px;
						width: 65%;
						background-color: #477673;
						border-radius: 5px;
						padding: 0;
						margin-left: 10px;
						margin-bottom: 4px;
						&:before {
							content: "";
							position: absolute;
							height: 1px;
							width: 3px;
							background-color: #477673;
							left: -6px;
							border-radius: 5px;
						}
					}
				}
			}
		}
		> li {
			.body {
				.body {
					ul {
						background-color: #f6f7fb;
					}
				}
			}
		}
		.dark {
			> li {
				background-color: #2f3c4e;
				.body {
					ul {
						background-color: #2f3c4e;
					}
				}
			}
		}
		.main-layout {
			> li {
				height: 70px;
				width: 86px;
				border: 1px solid $light-gray;
				border-radius: 5px;
				display: inline-block;
			}
			.box-layout {
				padding-left: 20px;
				padding-right: 20px;
				margin-top: 16px;
				.body {
					.badge {
						left: -14px;
					}
				}
			}
		}
		.sidebar-type,
		.sidebar-setting {
			> li {
				height: 70px;
				width: 86px;
				border: 1px solid $light-gray;
				border-radius: 5px;
				display: inline-block;
			}
		}
		.sidebar-type {
			li {
				&:first-child {
					.body {
						ul {
							li {
								&.bg-dark,
								&.sidebar {
									width: 100%;
									height: 10px;
									display: block;
								}
								&.bg-light,
								&.body {
									height: calc(100% - 10px);
								}
							}
						}
					}
				}
			}
		}
		.sidebar-bg-settings {
			display: flex;
			align-items: center;
			justify-content: space-between;
			li {
				height: 100px;
				width: 100%;
				border: 1px solid $light-gray;
				border-radius: 5px;
				display: inline-block;
				margin-right: 9px;
				box-shadow: 0 0 11px -2px rgba(128, 128, 128, 0.4);
			}
		}
		ul.layout-types {
			li {
				display: block;
				width: 100%;
				height: auto;
				text-align: center;
				margin-bottom: 20px;
				h6 {
					margin-top: 10px;
				}
				+ li {
					margin-left: unset;
				}
			}
		}
	}
	.customizer-footer {
		position: absolute;
		bottom: 0;
		padding: 25px;
		width: 100%;
		border-top: 1px solid $light-gray;
		background-color: $white;
	}
}
.layout-grid:not(.customizer-color) {
	li {
		padding: 5px;
		> .header {
			height: 9px;
			border-radius: 3px;
			position: relative;
			ul {
				position: absolute;
				top: -8px;
				left: 5px;
				> li {
					display: inline-block;
					width: 4px;
					height: 4px;
					border-radius: 5px;
					top: -2px;
					background-color: $danger-color !important;
					margin-right: 1px;
					padding: 0;
					&:nth-child(2) {
						background-color: $warning-color !important;
					}
					&:nth-child(3) {
						background-color: $success-color !important;
					}
				}
			}
		}
		> .body {
			background-color: $light-gray;
			height: calc(100% - 16px);
			border-radius: 3px;
			position: relative;
			margin-top: 5px;
			text-align: center;
			line-height: 3.1;
			ul {
				height: 100%;
				background-color: $white;
				.sidebar {
					width: 18px;
					height: 100%;
					border-radius: 3px;
					display: inline-block;
					margin-right: 3px;
					padding: 0;
					&.compact {
						width: 12px;
						& ~ .body {
							width: calc(100% - 19px);
						}
					}
					&.compact-icon {
						width: 7px;
						& ~ .body {
							width: calc(100% - 14px);
						}
					}
				}
				.body {
					width: calc(100% - 25px);
					padding: 0;
					margin: 0;
					height: 100%;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-radius: 3px;
					.badge {
						position: absolute;
					}
				}
			}
		}
	}
}
/**=====================
  77.Customizer CSS Ends
==========================**/

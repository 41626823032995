/**=====================
  01. According CSS start
==========================**/

.accordion-button {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: var(--#{$prefix}accordion-btn-padding-y)
		var(--#{$prefix}accordion-btn-padding-x);
	@include font-size($font-size-base);
	color: var(--#{$prefix}accordion-btn-color);
	text-align: left; // Reset button style
	background-color: $white;
	border: 0;
	border-radius: 0; /* Assicura che l'header chiuso non abbia bordi arrotondati */

	@include border-radius(0);
	overflow-anchor: none;
	@include transition(var(--#{$prefix}accordion-transition));

	&:not(.collapsed) {
		color: var(--#{$prefix}accordion-active-color);
		background-color: $white;
		/* 		box-shadow: inset 0 calc(-1 * var(--#{$prefix}accordion-border-width)) 0
			var(--#{$prefix}accordion-border-color); // stylelint-disable-line function-disallowed-list */

		&::after {
			background-image: var(--#{$prefix}accordion-btn-active-icon);
			transform: var(--#{$prefix}accordion-btn-icon-transform);
		}
	}
}

.custom-accordion-header .accordion-button::after {
	display: none;
}
.accordion-item {
	border: none;
	margin-bottom: 0;
}
.accordion-button:is(.collapsed) {
	background-color: rgb(247, 248, 248);
	color: #003f49;
}

.accordion-button {
	border-bottom: none;
	box-shadow: none;
	color: #001f24;
}

.accordion-button:not(.collapsed) {
	box-shadow: none;
	color: #001f24;
}
.accordion-button:not(.collapsed) {
	background-color: #fff; /* Colore di sfondo per l'header aperto */
	box-shadow: none;
	border-radius: 20px;
}

.accordion-body {
	background-color: #fff; /* Colore di sfondo per il corpo dell'accordion aperto */
	transition: background-color 0.3s ease;
	border-radius: 20px;
}

.accordion {
	border: none !important;
}
.default-according {
	.card {
		margin-bottom: 0;
		box-shadow: none !important;
		& + .card {
			margin-top: $according-card-top-margin;
		}
		.btn-link {
			font-weight: $according-btn-weight;
			color: $theme-body-font-color;
			font-size: 16px;
		}
		.btn-link {
			&:focus,
			&:hover {
				text-decoration: none;
			}
		}
		.card-header {
			padding: $according-card-header-padding;
			border-bottom: 1px solid $light-gray;
			i {
				position: initial;
				font-size: 20px;
				display: inline-block;
				vertical-align: text-bottom;
				margin-right: 5px;
			}
			h5 {
				margin-top: 2px;
			}
		}
		.card-body {
			border-top: none !important;
			padding: 20px;
			h5 {
				font-size: 14px;
				margin-bottom: 5px;
			}
			h6 {
				color: $light-text;
				font-size: 12px;
				margin-bottom: 5px;
			}
			ul {
				&.rating {
					li {
						display: inline-block;
					}
				}
			}
		}
	}
}
.default-according.style-1 {
	button {
		width: 100%;
		text-align: left;
		&:before {
			right: 20px;
			position: absolute;
			transition: 0.4s;
		}
		&[aria-expanded="true"] {
			&:before {
				content: $according-open-icon;
				font-family: $according-card-header-icon;
			}
		}
		&[aria-expanded="false"] {
			&:before {
				content: $according-close-icon;
				font-family: $according-card-header-icon;
			}
		}
	}
}
@media (max-width: 480px) {
	.default-according {
		.card {
			.card-header {
				padding-right: 32px;
				h5 {
					font-size: 16px;
				}
			}
		}
		&.style-1 {
			button {
				&:before {
					right: 10px;
				}
			}
		}
	}
}
/**=====================
  01. According CSS Ends
==========================**/

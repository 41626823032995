/* CSS per centrare il testo nella colonna del giorno */
.custom-day-header {
	text-align: center;
}

.custom-day-header div:first-child {
	font-size: 14px;
}

.custom-day-header div:last-child {
	font-weight: bold;
	font-size: 16px;
}

/* Stile degli slot selezionabili */
.rbc-time-slot {
	height: 40px;
}
.rbc-header {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.rbc-time-view .rbc-allday-cell {
	height: 0px;
}

/* Stile per gli eventi */
.rbc-event {
	background-color: #ace9e5 !important; /* Colore dello sfondo degli eventi */
	color: #333;
	padding: 0px;
	text-align: center;
	border-radius: 0px;
}

.rbc-event-label {
	display: none;
}
.rbc-timeslot-group {
	border-bottom: 1px solid #f0f0f0;
	padding: 0;
}
rbc-event-content {
}
.rbc-day-slot .rbc-events-container {
	margin-right: 0px !important;
}
.rbc-label rbc-time-header-gutter {
	display: "flex";
	justify-content: "center";
	align-items: "center";
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
	height: 10% !important;
	border: 1px solid #fff;
}

.rbc-timeslot-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: content;
	height: 55px !important;
}

.rbc-calendar {
	height: auto !important;
}

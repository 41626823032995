.custom-step-service
	> .ant-steps.ant-steps-dot
	.ant-steps-item-process
	.ant-steps-item-icon {
	margin-left: 41px !important;
	margin-inline: 0 !important;
}

.ant-steps.ant-steps-dot
	.ant-steps-item-process
	.ant-steps-icon:first-child
	.ant-steps-icon-dot {
	inset-inline-start: 0;
	width: 20px;
	height: 20px;
	top: -5px;
}

.ant-steps
	.ant-steps-item-process
	.ant-steps-item-icon
	> .ant-steps-icon
	.ant-steps-icon-dot {
	background-color: #fff;
	border: 4px solid #2f4f4f;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #2f4f4f;
}

.ant-steps-item-tail {
	left: 0; /* Elimina lo spazio a sinistra della barra */
}

.ant-steps-item-icon {
	margin-left: 0; /* Riduce il margine a sinistra del cerchio */
}

.ant-steps-item {
	padding-left: 0; /* Elimina il padding iniziale */
}

custom-stepper {
	margin-top: 100px !important;
}

.custom-stepper .ant-steps-item-title {
	display: none; /* Nasconde i titoli degli step */
}

.custom-stepper .ant-steps-item-tail::after {
	background-color: #d9d9d9; /* Cambia il colore della linea */
	height: 2px; /* Riduce l'altezza della barra */
}

.custom-stepper .ant-steps-item-icon {
	width: 8px; /* Riduce la dimensione del punto */
	height: 8px;
	background-color: #2f4f4f; /* Cambia il colore del punto */
	border-radius: 50%;
	border: none; /* Rimuove i bordi */
}

.custom-stepper .ant-steps-item-process .ant-steps-item-icon {
	background-color: #2f4f4f; /* Colore del punto attivo */
}

.ant-steps.ant-steps-dot
	.ant-steps-item-process
	.ant-steps-icon:first-child
	.ant-steps-icon-dot {
	inset-inline-start: 0;
	width: 20px;
	height: 20px;
	top: -5px;
}

.ant-steps
	.ant-steps-item-process
	.ant-steps-item-icon
	> .ant-steps-icon
	.ant-steps-icon-dot {
	background-color: #fff;
	border: 4px solid #2f4f4f;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #2f4f4f;
}

.ant-steps-item-tail {
	left: 0; /* Elimina lo spazio a sinistra della barra */
}

.ant-steps-item-icon {
	margin-left: 0; /* Riduce il margine a sinistra del cerchio */
}

.ant-steps-item {
	padding-left: 0; /* Elimina il padding iniziale */
}

/**=====================
  70. SVG CSS Start
==========================**/
.error2 {
	.shp1 {
		opacity: 0.702;
		fill: #ffffff;
	}
	.shp2 {
		opacity: 0.2;
		fill: #e2c636;
	}
	.shp3 {
		opacity: 0.302;
		fill: #ffffff;
	}
	.shp5 {
		opacity: 0.2;
		fill: #000000;
	}
	.shp6 {
		opacity: 0.2;
		fill: #477673;
	}
	.shp7 {
		opacity: 0.302;
		fill: #e2c636;
	}
	.shp8 {
		opacity: 0.8;
		fill: #ffffff;
	}
	.shp9 {
		fill: #ba895d;
	}
	.shp10 {
		fill: #ffffff;
	}
	.shp11 {
		opacity: 0.502;
		fill: #ffffff;
	}
	.shp12 {
		opacity: 0.6;
		fill: #e2c636;
	}
	.shp13 {
		opacity: 0.502;
		fill: #e2c636;
	}
	.shp14 {
		opacity: 0.6;
		fill: #ffffff;
	}
}
.error3 {
	.shp0 {
		fill: #ebebeb;
	}
	.shp1 {
		fill: #e0e0e0;
	}
	.shp2 {
		fill: #477673;
	}
	.shp3 {
		fill: #ffffff;
	}
	.shp4 {
		opacity: 0.8;
		fill: #ffffff;
	}
	.shp5 {
		fill: #000000;
	}
	.shp6 {
		fill: #ba895d;
	}
	.shp7 {
		fill: #263238;
	}
	.shp8 {
		fill: #ffbe9d;
	}
	.shp9 {
		fill: #eb996e;
	}
}
.error4 {
	.shp0 {
		fill: #e0e0e0;
	}
	.shp1 {
		fill: #455a64;
	}
	.shp2 {
		fill: #477673;
	}
	.shp3 {
		opacity: 0.4;
		mix-blend-mode: soft-light;
		fill: #ffffff;
	}
	.shp4 {
		opacity: 0.051;
		mix-blend-mode: multiply;
		fill: #000000;
	}
	.shp5 {
		opacity: 0.4;
		fill: #ffffff;
	}
	.shp6 {
		opacity: 0.2;
		fill: #000000;
	}
	.shp7 {
		opacity: 0.102;
		fill: #000000;
	}
	.shp8 {
		fill: #ffffff;
	}
	.shp9 {
		opacity: 0.2;
		fill: #ffffff;
	}
	.shp10 {
		fill: #000000;
	}
	.shp11 {
		opacity: 0.149;
		fill: #000000;
	}
	.shp12 {
		fill: #37474f;
	}
	.shp13 {
		fill: #263238;
	}
	.shp14 {
		opacity: 0.302;
		fill: #000000;
	}
	.shp15 {
		opacity: 0.502;
		fill: #000000;
	}
	.shp16 {
		opacity: 0.702;
		fill: #000000;
	}
	.shp17 {
		fill: #fafafa;
	}
	.shp18 {
		fill: #ffa8a7;
	}
	.shp19 {
		fill: #f28f8f;
	}
}
/**=====================
  70. SVG  CSS ends
==========================**/

/**=====================
  13.  Card CSS Start
==========================**/
//Card css
.card {
	margin-bottom: $card-margin-bottom;
	border: none;
	transition: all 0.3s ease;
	height: fit-content;
	letter-spacing: 0.5px;
	padding: 0;
	border-radius: $card-border-radious;
	background-color: $white;
	.card-header {
		padding: $card-padding;
		border-bottom: none;
		border-top-left-radius: $card-border-radious;
		border-top-right-radius: $card-border-radious;
		position: relative;
		background-color: $white;
		&.card-no-border {
			border-bottom: none !important;
		}
		h4 {
			font-size: 24px;
			font-weight: 500;
		}
		h5 {
			font-size: 18px;
			font-weight: 500;
			&:not(.mb-0) {
				text-transform: $card-header-font-transform;
				font-weight: $card-header-font-weight;
				line-height: 1.3;
				color: $dark-color;
				margin: 0;
			}
		}
		> span {
			font-size: $card-header-span-size;
			color: $card-header-span-color;
			margin-top: 5px;
			display: block;
			letter-spacing: 1px;
		}
		&.bg-primary,
		&.bg-secondary,
		&.bg-success,
		&.bg-danger,
		&.bg-warning {
			h4 {
				color: $white;
			}
			h5 {
				color: $white;
			}
			> span {
				color: $white;
			}
		}
	}
	.card-body {
		padding: $card-padding;
		background-color: $card-body-bg-color;
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
		&.bg-primary,
		&.bg-secondary,
		&.bg-success,
		&.bg-danger,
		&.bg-warning {
			p {
				color: $white;
			}
		}
	}
	.sub-title {
		border-bottom: 1px solid rgba($primary-color, 0.2);
		padding-bottom: 5px;
		margin-bottom: 8px;
		font-size: 18px;
	}
	.card-footer {
		background-color: $card-body-bg-color;
		border-top: $card-border-color;
		padding: $card-padding;
		border-bottom-left-radius: $card-border-radious;
		border-bottom-right-radius: $card-border-radious;
		&.bg-primary,
		&.bg-secondary,
		&.bg-success,
		&.bg-danger,
		&.bg-warning {
			color: $white;
		}
	}
}
.card {
	&.card-load {
		.card-loader {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			background-color: rgba(255, 255, 255, 0.7);
			z-index: 8;
			align-items: center;
			justify-content: center;
			i {
				margin: 0 auto;
				color: var(--theme-default);
				font-size: 20px;
			}
		}
	}
}
.card {
	&.full-card {
		position: fixed;
		top: 0;
		z-index: 99999;
		box-shadow: none;
		right: 0;
		border-radius: 0;
		border: 1px solid $light-semi-gray;
		width: calc(100vw - 12px);
		height: calc(100vh);
		.card-body {
			overflow: auto;
		}
	}
}
.card-absolute {
	margin-top: 20px;
	.card-header {
		position: absolute;
		top: -20px;
		margin-bottom: 30px;
		left: 15px;
		border-radius: 0.25rem;
		padding: 10px 15px;
		h5 {
			font-size: 17px;
			&.text-white {
				-webkit-text-fill-color: $white;
			}
		}
	}
	.card-body {
		margin-top: 10px;
	}
}
.card-header {
	.border-tab {
		margin-bottom: -13px;
	}
}
.custom-card {
	overflow: hidden;
	padding: 30px;
	.card-header {
		padding: 0;
		img {
			transform: scale(1.5);
		}
	}
	.card-profile {
		text-align: center;
		img {
			margin: 0 auto;
			height: 110px;
			padding: 7px;
			background-color: $light-background;
			z-index: 1;
			position: relative;
		}
	}
	.card-social {
		text-align: center;
		padding-top: 15px;
		padding-bottom: 15px;
		li {
			display: inline-block;
			&:nth-child(n + 2) {
				margin-left: 10px;
			}
			a {
				width: 35px;
				height: 35px;
				border-radius: 5px;
				background-color: rgba($primary-color, 0.1);
				transition: all 0.3s ease;
				color: var(--theme-default);
				display: flex;
				align-items: center;
				justify-content: center;
			}
			&:hover {
				a {
					background-color: var(--theme-default);
					color: $white;
				}
			}
		}
	}
	.profile-details {
		h4 {
			margin-top: 10px;
			font-weight: 600;
			color: $theme-body-font-color;
		}
		h6 {
			margin-bottom: 10px;
			margin-top: 10px;
			color: $light-text;
			font-size: 14px;
		}
	}
	.card-footer {
		padding: 0;
		> div {
			+ div {
				border-left: 1px solid $light-semi-gray;
			}
			padding: 15px;
			text-align: center;
			h3 {
				margin-bottom: 0;
				font-size: 20px;
				font-weight: 600;
				span {
					font-weight: 600;
				}
			}
			h6 {
				font-size: 13px;
				color: $light-text;
			}
			i {
				font-size: 24px;
				display: inline-block;
				margin-bottom: 15px;
			}
			.m-b-card {
				margin-bottom: 10px;
			}
		}
	}
}
/**=====================
  13. Card CSS End
==========================**/
